import React, { useEffect } from "react";

import {
    SEO,
} from "@components";


export default function SlackPage() {
    useEffect(() => {
        window.location.replace("https://join.slack.com/t/react-brasil/shared_invite/zt-2ad5yphet-fDxyDePRBC2iJ2M6_N7YhA");
    });

    return (
        <main>
            <SEO title="Slack React Brasil" />
        </main>
    );
}
